import { Classes } from "@blueprintjs/core";
import { CollectionRole, ProjectRole } from "@hex/common";
import React, { ReactNode, useCallback } from "react";
import styled from "styled-components";

import { HexSwitch, HexTooltip } from "../../hex-components";
import { typedMemo } from "../../util/typedMemo";

const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  padding: 6px 16px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  min-width: 0;
`;

const InfoTitleDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;

  color: ${({ theme }) => theme.fontColor.DEFAULT};
`;

const InfoDescription = styled.div`
  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
  line-height: 16px;
`;

const StatusContainer = styled.div`
  display: flex;
  flex: none;
  gap: 8px;
  align-items: center;
`;

const StatusText = styled.div`
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
`;

const RoleContainer = styled.div<{ $subtle?: boolean }>`
  margin-left: 5px;

  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.borderColor.DEFAULT};
  ${({ $subtle }) =>
    $subtle &&
    `
        box-shadow: none;
    `}

  > div {
    border-left: none;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  width: 28px;
`;

export interface WiderSharingOptionProps<
  R extends ProjectRole | CollectionRole,
> {
  icon: ReactNode;
  title: string;
  // optional element to render after the title
  titleIcon?: ReactNode;
  selectedRole?: R;
  label?: string;
  tooltipContent?: string;
  // whether or not this type of sharing has been turned on
  enabled: boolean;
  onEnabledToggle: (enabled: boolean) => void;
  // whether or not to allow the current user to interact with this option
  disabled?: boolean;
  disabledMessage?: string;
  rolePicker?: ReactNode;
  $subtle?: boolean;
  "data-cy"?: string;
}

export const WiderSharingOption = typedMemo(function WiderSharingOption<
  R extends ProjectRole | CollectionRole,
>({
  $subtle,
  "data-cy": dataCy,
  disabled = false,
  disabledMessage,
  enabled,
  icon,
  label,
  onEnabledToggle,
  rolePicker,
  title,
  titleIcon,
  tooltipContent,
}: WiderSharingOptionProps<R>) {
  const toggleCallback = useCallback(
    (evt: React.FormEvent<HTMLInputElement>) => {
      onEnabledToggle(evt.currentTarget.checked);
    },
    [onEnabledToggle],
  );

  return (
    <Container>
      <InfoContainer>
        {icon}
        <InfoTitleDescriptionContainer>
          <HexTooltip content={tooltipContent} disabled={!tooltipContent}>
            <div className={Classes.TEXT_OVERFLOW_ELLIPSIS} title={title}>
              {title}
            </div>
          </HexTooltip>
          {label && <InfoDescription>{label}</InfoDescription>}
        </InfoTitleDescriptionContainer>
        {titleIcon}
      </InfoContainer>
      <StatusContainer>
        <StatusText>
          {!enabled ? null : (
            <>
              <RoleContainer $subtle={$subtle}>{rolePicker}</RoleContainer>
            </>
          )}
        </StatusText>
        <ToggleContainer>
          <HexTooltip
            content={disabledMessage ?? ""}
            disabled={!disabledMessage}
          >
            <HexSwitch
              checked={enabled}
              data-cy={dataCy}
              disabled={disabled}
              inline={true}
              onChange={toggleCallback}
            />
          </HexTooltip>
        </ToggleContainer>
      </StatusContainer>
    </Container>
  );
});
