import { IconName } from "@blueprintjs/core";
import React from "react";

import { TERMINOLOGY } from "../../hooks/useTerminologyText";
import { CyData } from "../../util/cypress";
import {
  DataSourcesIcon,
  EnvironmentIcon,
  FilesIcon,
  HelpIcon,
  HistoryIcon,
  ImportReferenceIcon,
  OutlineIcon,
  ScheduledRunsIcon,
  SearchFilledIcon,
  VariablesIcon,
} from "../icons/CustomIcons";

export const DEFAULT_SIDEBAR_WIDTH = 341;
export const COLLAPSED_SIDEBAR_WIDTH = 48;
export const MAX_SIDEBAR_WIDTH = 600;

export enum SidebarTab {
  OUTLINE = "outline",
  DATA_CONNECTIONS = "data-connections",
  ENVIRONMENT = "environment",
  FILES = "files",
  VARIABLES = "variables",
  SCHEDULED_RUNS = "scheduled-runs",
  HISTORY = "history",
  SEARCH = "search",
  IMPORT_REFERENCES = "import-references",
  LEARN = "learn",
}

interface SidebarTabData {
  icon: IconName | JSX.Element;
  slug: SidebarTab;
  title: string;
  cyData?: string;
  // optionally set if the title is different in the component view
  componentsTitle?: string;
  location?: "top" | "bottom";
}

export const SidebarTabInfo: Record<SidebarTab, SidebarTabData> = {
  [SidebarTab.IMPORT_REFERENCES]: {
    icon: <ImportReferenceIcon />,
    slug: SidebarTab.IMPORT_REFERENCES,
    title: "Import references",
  },
  [SidebarTab.OUTLINE]: {
    icon: <OutlineIcon />,
    slug: SidebarTab.OUTLINE,
    title: "Outline",
    cyData: CyData.OUTLINE_TAB,
  },
  [SidebarTab.SEARCH]: {
    icon: <SearchFilledIcon />,
    slug: SidebarTab.SEARCH,
    title: "Find",
  },
  [SidebarTab.DATA_CONNECTIONS]: {
    icon: <DataSourcesIcon />,
    slug: SidebarTab.DATA_CONNECTIONS,
    cyData: CyData.DATA_CONNECTION_TAB,
    title: TERMINOLOGY.dataBrowserText,
  },
  [SidebarTab.ENVIRONMENT]: {
    icon: <EnvironmentIcon />,
    slug: SidebarTab.ENVIRONMENT,
    title: "Environment",
  },
  [SidebarTab.FILES]: {
    icon: <FilesIcon />,
    slug: SidebarTab.FILES,
    title: "Files",
  },
  [SidebarTab.VARIABLES]: {
    icon: <VariablesIcon />,
    slug: SidebarTab.VARIABLES,
    cyData: CyData.VARIABLES_TAB,
    title: "Variables",
  },
  [SidebarTab.SCHEDULED_RUNS]: {
    icon: <ScheduledRunsIcon />,
    slug: SidebarTab.SCHEDULED_RUNS,
    title: "Scheduled runs",
    cyData: CyData.SCHEDULED_RUNS_SIDEBAR_TAB,
  },
  [SidebarTab.HISTORY]: {
    icon: <HistoryIcon />,
    slug: SidebarTab.HISTORY,
    cyData: CyData.VERSION_TAB,
    title: "History & versions",
  },
  [SidebarTab.LEARN]: {
    icon: <HelpIcon />,
    slug: SidebarTab.LEARN,
    title: "Help & support",
    location: "bottom",
    cyData: CyData.HELP_AND_SUPPORT,
  },
};
