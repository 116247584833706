import {
  AirlockBlockConfig,
  AppSessionId,
  CellId,
  HexVersionId,
  SQLCellBlockConfig,
  assertNever,
  chartEncodingTitle,
  notEmpty,
  stableEmptyArray,
} from "@hex/common";
import { keyBy, memoize, partition } from "lodash";
import { createSelector } from "reselect";

import { GridRowMpFragment } from "../../hex-version-multiplayer/HexVersionMPModel.generated.js";
import {
  SharedFilterSessionStateMP,
  appSessionMPSelectors,
} from "../../redux/slices/appSessionMPSlice.js";
import {
  CellContentsMP,
  CellMP,
  SharedFilterMP,
  hexVersionMPSelectors,
} from "../../redux/slices/hexVersionMPSlice.js";
import { RootState } from "../../redux/store.js";

import { sharedFilterOperatorToValidInputTypes } from "./configurator/sharedFilterDefaults.js";
import { getSharedFilterLinks } from "./sharedFilterLinkUtils.js";
import { isFilterableCellType } from "./sharedFilterUtils.js";
import {
  CellInfo,
  PersistedSharedFilter,
  SessionSharedFilter,
} from "./types.js";

export const getSharedFilterSelectors = memoize(
  (hexVersionId: HexVersionId, appSessionId: AppSessionId) => {
    const filterSelectors =
      hexVersionMPSelectors.getSharedFilterSelectors(hexVersionId);
    const sessionSelectors =
      appSessionMPSelectors.getSharedFilterSessionStateSelectors(appSessionId);
    const cellSelectors = hexVersionMPSelectors.getCellSelectors(hexVersionId);
    const cellContentSelectors =
      hexVersionMPSelectors.getCellContentSelectors(hexVersionId);
    const cellLabelSelectors =
      hexVersionMPSelectors.getCellContentAwareSelectors(hexVersionId);
    const gridRowSelectors =
      hexVersionMPSelectors.getGridRowSelectors(hexVersionId);

    return {
      cellIdsInApp: createSelector(gridRowSelectors.selectAll, (gridRows) => {
        return getCellIdsInApp(gridRows);
      }),
      cellsInApp: createSelector(
        cellSelectors.selectAll,
        gridRowSelectors.selectAll,
        (cells, gridRows) => getCellsInApp(cells, gridRows),
      ),
      cellInfo: createSelector(
        (state: RootState) => state,
        cellSelectors.selectFlattenedSorted,
        cellContentSelectors.selectEntities,
        (state, cells, cellContentsByCellId) => {
          if (cells == null || cellContentsByCellId == null) {
            return stableEmptyArray<CellInfo>();
          }

          const ret: CellInfo[] = [];
          cells.forEach((cell) => {
            const cellContent = cellContentsByCellId[cell.id];
            if (cellContent == null) {
              return;
            }

            const label =
              cellLabelSelectors.selectLabelByCellId(state, cell.id) ?? "Cell";

            let betterLabel: string = label;
            if (cell.label == null && cellContent.__typename === "ChartCell") {
              if (
                cellContent.chartSpec.type === "layered" &&
                cellContent.chartSpec.layers.length > 0
              ) {
                // we only really support 1 layer right now
                const layer = cellContent.chartSpec.layers[0];

                const xAxis =
                  layer.xAxis.title ??
                  chartEncodingTitle({
                    dataFrameColumn: layer.xAxis.dataFrameColumn,
                    aggregate: layer.xAxis.aggregate,
                  });
                const yAxis = layer.series
                  .flatMap((series) => {
                    return (
                      series.axis.title ??
                      series.dataFrameColumns.map((column) =>
                        chartEncodingTitle({
                          dataFrameColumn: column,
                          aggregate: series.axis.aggregate,
                        }),
                      )
                    );
                  })
                  .filter(notEmpty)
                  .join(", ");

                if (xAxis && yAxis) {
                  betterLabel = `${yAxis} vs. ${xAxis}`;
                }
              }
            }

            ret.push({
              cellId: cell.id,
              staticCellId: cell.staticId,
              cellType: cell.cellType,
              label,
              betterLabel,
            });
          });
          return ret;
        },
      ),
      filterableCells: createSelector(
        cellSelectors.selectAll,
        cellContentSelectors.selectEntities,
        (cells, cellContentById) => {
          return getFilterableCells(
            cells,
            (cellId) => cellContentById?.[cellId],
          );
        },
      ),
      sharedFilters: createSelector(
        filterSelectors.selectAll,
        sessionSelectors.selectAll,
        cellSelectors.selectAll,
        cellContentSelectors.selectEntities,
        gridRowSelectors.selectAll,
        (
          sharedFilters,
          sessionStates,
          cells,
          cellContentById,
          gridRows,
          // eslint-disable-next-line max-params -- createSelector args
        ) => {
          const getCellContents = (
            cellId: CellId,
          ): CellContentsMP | undefined => cellContentById?.[cellId];

          const filterableCells = getFilterableCells(cells, getCellContents);
          const cellsInApp = getCellsInApp(filterableCells, gridRows);

          const [linkedSessions, unlinkedSessions] = partition(
            sessionStates ?? [],
            (session) => session.filterId != null,
          );

          const linkedSessionsByFilterId = keyBy(
            linkedSessions,
            (session) => session.filterId ?? "",
          );

          const persistedFilters = (sharedFilters ?? []).map((filter) => {
            const session = linkedSessionsByFilterId[filter.id];
            return toPersistedSharedFilter({
              filter:
                session != null ? resolveSharedFilter(filter, session) : filter,
              allCells: filterableCells,
              cellsInApp,
              getCellContents: (cellId) => cellContentById?.[cellId],
            });
          });

          const sessionFilters = unlinkedSessions.map((session) =>
            toSessionSharedFilter({
              filter: session,
              allCells: filterableCells,
              cellsInApp,
              getCellContents,
            }),
          );
          return [...persistedFilters, ...sessionFilters];
        },
      ),
    };
  },
  // Warning: This memoization key is dependent on the appSessionId AND hexVersionId.
  // By default, lodash will use only the first argument to determine the cache key.
  (appSessionId, hexVersionId) => `${appSessionId}-${hexVersionId}`,
);

const getCellIdsInApp = memoize((gridRows: GridRowMpFragment[] | null) => {
  const gridElements = (gridRows ?? [])
    .flatMap((row) => row.gridColumns)
    .flatMap((column) => column.gridElements);

  return new Set(
    gridElements
      .filter(
        (element) => element.type === "CELL" && element.deletedDate == null,
      )
      .map((element) => element.entityId as CellId),
  );
});

export function getCellsInApp(
  cells: CellMP[] | null,
  gridRows: GridRowMpFragment[] | null,
): CellMP[] {
  const cellIdsInApp = getCellIdsInApp(gridRows);
  return (cells ?? [])?.filter((cell) => {
    return cellIdsInApp.has(cell.id);
  });
}

export function getFilterableCells(
  allCells: CellMP[] | null,
  getCellContents: (cellId: CellId) => CellContentsMP | undefined,
): CellMP[] {
  // handle block cells by keeping only the relevant one
  const cells: CellMP[] = [];
  const cellsToSkip = new Set<CellId>();

  for (const cell of allCells ?? []) {
    const cellContents = getCellContents(cell.id);

    // if not filterable, move along
    if (
      cell.deletedDate != null ||
      cellContents == null ||
      (cellContents.__typename !== "BlockCell" &&
        !isFilterableCellType(cellContents.__typename))
    ) {
      continue;
    }

    if (
      cellContents.__typename === "BlockCell" &&
      cellContents.blockConfig != null
    ) {
      if (SQLCellBlockConfig.guard(cellContents.blockConfig)) {
        // for block cells, just use the sql cell that it's pointing to, so skip chart
        cellsToSkip.add(cellContents.blockConfig.chartCellId);
        // we don't want to add the block cell itself, we only care
        // about what it's pointing to
        continue;
      } else if (AirlockBlockConfig.guard(cellContents.blockConfig)) {
        // TODO(HAL-946): Figure out what we want to do here we probably want
        // to skip all cells in an airlock so shared filters don't get applied
        continue;
      } else {
        assertNever(cellContents.blockConfig, cellContents.blockConfig);
      }
    }

    if (cellsToSkip.has(cell.id)) {
      continue;
    }

    cells.push(cell);
  }

  return cells;
}

function resolveSharedFilter(
  sharedFilter: SharedFilterMP,
  sharedFilterSessionState: SharedFilterSessionStateMP,
): SharedFilterMP {
  return {
    ...sharedFilter,
    autoLinks: sharedFilterSessionState.autoLinks ?? sharedFilter.autoLinks,
    manualLinks:
      sharedFilterSessionState.manualLinks ?? sharedFilter.manualLinks,
    operator: sharedFilterSessionState.operator ?? sharedFilter.operator,
    defaultValue: sharedFilterSessionState.value ?? sharedFilter.defaultValue,
  };
}

function toPersistedSharedFilter({
  allCells,
  cellsInApp,
  filter,
  getCellContents,
}: {
  filter: SharedFilterMP;
  allCells: CellMP[];
  cellsInApp: CellMP[];
  getCellContents: (cellId: CellId) => CellContentsMP | undefined;
}): PersistedSharedFilter {
  return {
    type: "persisted",
    id: filter.id,
    name: filter.name,
    inputType: filter.type,
    operator: filter.operator ?? "EQ",
    options: filter.options,
    autoLinks: filter.autoLinks,
    manualLinks: filter.manualLinks,
    value: filter.defaultValue,
    createdDate: filter.createdDate,
    cellLinks: getSharedFilterLinks({
      autoLinks: filter.autoLinks,
      allCells,
      cellsInApp,
      getCellContents,
      manualLinks: filter.manualLinks,
    }),
  };
}

function toSessionSharedFilter({
  allCells,
  cellsInApp,
  filter,
  getCellContents,
}: {
  filter: SharedFilterSessionStateMP;
  allCells: CellMP[];
  cellsInApp: CellMP[];
  getCellContents: (cellId: CellId) => CellContentsMP | undefined;
}): SessionSharedFilter {
  const operator = filter.operator ?? "EQ";
  return {
    type: "session",
    id: filter.id,
    inputType: sharedFilterOperatorToValidInputTypes[operator][0],
    operator,
    options: filter.options,
    autoLinks: filter.autoLinks ?? [],
    manualLinks: filter.manualLinks ?? [],
    value: filter.value,
    createdDate: filter.createdDate,
    cellLinks: getSharedFilterLinks({
      autoLinks: filter.autoLinks ?? [],
      allCells,
      cellsInApp,
      getCellContents,
      manualLinks: filter.manualLinks ?? [],
    }),
  };
}
