/**
 * This is a seperate file to help with code splitting
 */
import React from "react";

import {
  ChartCellIcon,
  CodeCellIcon,
  ComponentIcon,
  DropdownInputIcon,
  FilterCellIcon,
  SQLCellIcon,
  TextCellIcon,
  UnorderedListIcon,
  WritebackCellIcon,
} from "../icons/CustomIcons.js";

type CellFilterShowTypeKey =
  | "All"
  | "Code"
  | "Components"
  | "Input"
  | "Sql"
  | "Text"
  | "Transformations"
  | "Visualizations"
  | "Writeback";

export const CellFilterShowType: Record<
  CellFilterShowTypeKey,
  {
    display: string;
    label: string;
    typename?: string | readonly string[];
    icon?: JSX.Element;
  }
> = {
  All: {
    display: "All",
    label: "all",
    icon: <UnorderedListIcon />,
  },
  Code: {
    display: "Code",
    label: "Code",
    typename: "CodeCell",
    icon: <CodeCellIcon />,
  },
  Components: {
    display: "Components",
    label: "Components",
    typename: "ComponentImportCell",
    icon: <ComponentIcon />,
  },
  Input: {
    display: "Input parameters",
    label: "Input parameters",
    typename: "Parameter",
    icon: <DropdownInputIcon />,
  },
  Sql: {
    display: "SQL",
    label: "SQL",
    typename: "SqlCell",
    icon: <SQLCellIcon />,
  },
  Text: {
    display: "Text + Markdown",
    label: "Text + Markdown",
    typename: ["MarkdownCell", "TextCell"],
    icon: <TextCellIcon />,
  },
  Transformations: {
    display: "Transformations",
    label: "Transformations",
    typename: ["FilterCell", "PivotCell"],
    icon: <FilterCellIcon />,
  },
  Visualizations: {
    display: "Visualizations",
    label: "Visualizations",
    typename: ["ChartCell", "DisplayTableCell", "MetricCell", "MapCell"],
    icon: <ChartCellIcon />,
  },
  Writeback: {
    display: "Writeback",
    label: "Writeback",
    typename: ["WritebackCell"],
    icon: <WritebackCellIcon />,
  },
};
